:root {
  --primary-color: #ab96ff;
  --primary-hover: #9a82ff;
  --bg-dark: #0a0a0a;
  --card-dark: #111111;
  --text-dark: rgba(255, 255, 255, .92);
  --card-shadow-dark: 0 8px 32px rgba(0, 0, 0, .4);
  --header-gradient-dark: linear-gradient(135deg, #322b47 0%, #1a1a2e 100%);
  --glow-effect: 0 0 20px rgba(171, 150, 255, .3);
  --error-color: #ff4646
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Space Grotesk, sans-serif
}

body {
  background: var(--bg-dark);
  color: var(--text-dark);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px
}

.call-container {
  background: var(--card-dark);
  width: 100%;
  max-width: 400px;
  border-radius: 24px;
  box-shadow: var(--card-shadow-dark);
  overflow: hidden;
  transition: all .3s ease;
  border: 1px solid rgba(171, 150, 255, .1);
  position: relative
}

.help-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ab96ff1a;
  border: 1px solid rgba(171, 150, 255, .2);
  color: var(--text-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s ease;
  z-index: 10
}

.help-button:hover {
  background: #ab96ff33;
  transform: translateY(-2px)
}

.help-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.9);
  background: var(--card-dark);
  padding: 32px;
  border-radius: 24px;
  box-shadow: var(--card-shadow-dark);
  max-width: 480px;
  width: 90%;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease
}

.help-dialog.active {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1)
}

.help-content h2 {
  color: var(--primary-color);
  margin-bottom: 16px;
  font-size: 24px
}

.help-content p {
  color: var(--text-dark);
  line-height: 1.6;
  margin-bottom: 24px
}

.help-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end
}

.help-site-button {
  background: var(--primary-color);
  color: var(--bg-dark);
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all .2s ease;
  display: flex;
  align-items: center;
  gap: 8px
}

.help-site-button:hover {
  background: var(--primary-hover);
  transform: translateY(-2px)
}

.help-close-button {
  background: #ab96ff1a;
  color: var(--text-dark);
  border: 1px solid rgba(171, 150, 255, .2);
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all .2s ease
}

.help-close-button:hover {
  background: #ab96ff33;
  transform: translateY(-2px)
}

.call-header {
  background: var(--header-gradient-dark);
  color: #fff;
  padding: 24px;
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100%; /* Garante que o header ocupe toda a largura */
  box-sizing: border-box; /* Inclui o padding no tamanho total da largura */
}

.call-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 0%, rgba(255, 255, 255, .1) 100%);
  z-index: 1;
}

.avatar {
  width: 80px;
  height: 80px;
  background: var(--card-dark);
  border-radius: 50%;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  border: 2px solid rgba(171, 150, 255, .3);
  box-shadow: var(--glow-effect);
  overflow: hidden
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.avatar:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, .1) 0%, transparent 70%);
  animation: pulse 2s infinite;
  pointer-events: none
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1
  }

  to {
    transform: scale(1.5);
    opacity: 0
  }
}

.avatar:hover {
  transform: scale(1.05)
}

.call-status {
  font-size: 20px;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .2)
}

.call-timer {
  font-size: 16px;
  opacity: .9;
  font-family: Space Grotesk, monospace;
  position: relative;
  z-index: 2;
  letter-spacing: 2px;
  color: #ab96ffcc;
  text-shadow: 0 0 10px rgba(171, 150, 255, .3)
}

.wave-animation {
  width: 100%; /* Garante que ocupe toda a largura disponível */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center; /* Distribui as barras de forma equidistante */
  gap: 8px;
  margin: 20px 0;
  background: #ab96ff0d;
  border-radius: 12px;
  padding: 10px;
}

.wave-bar {
  width: 4px;
  height: 20px;
  background: var(--primary-color);
  border-radius: 2px;
  animation: wave 1s ease-in-out infinite;
  box-shadow: 0 0 8px #ab96ff66
}

.wave-bar:nth-child(2) {
  animation-delay: .1s
}

.wave-bar:nth-child(3) {
  animation-delay: .2s
}

.wave-bar:nth-child(4) {
  animation-delay: .3s
}

.wave-bar:nth-child(5) {
  animation-delay: .4s
}

@keyframes wave {

  0%,
  to {
    height: 20px;
    opacity: .5
  }

  50% {
    height: 40px;
    opacity: 1
  }
}

.call-controls {
  width: 100%; /* Garante que ocupe toda a largura disponível */
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 24px;
  gap: 16px;
  background: #ab96ff08;
  border-top: 1px solid rgba(171, 150, 255, 0.1);
}

.control-button {
  background: none;
  border: 1px solid rgba(171, 150, 255, .2);
  width: auto;
  min-width: 56px;
  height: 56px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all .2s ease;
  color: var(--text-dark);
  padding: 0 16px;
  font-size: 14px;
  white-space: nowrap
}

.control-button:hover {
  transform: translateY(-2px);
  background: #ab96ff1a;
  border-color: #ab96ff4d
}

.control-button.mute {
  background: #ab96ff0d
}

.control-button.mute:hover {
  background: #ab96ff1a
}

.control-button.mute[data-active=true] {
  background: #ab96ff4d;
  color: var(--bg-dark);
  box-shadow: var(--glow-effect)
}

.control-button.end-call {
  background: #dc3545;
  border: none;
  color: #fff
}

.control-button.end-call:hover {
  background: #bb2d3b;
  box-shadow: 0 4px 12px #dc35454d
}

.control-button.call-again {
  background: #28a745;
  border: none;
  color: #fff;
  grid-column: span 3;
  width: 100%
}

.control-button.call-again:hover {
  background: #218838;
  box-shadow: 0 4px 12px #28a7454d
}

.status-text {
  text-align: center;
  color: #fff9;
  margin: 16px 0;
  font-size: 14px;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(171, 150, 255, .3)
}

.register-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--card-dark);
  padding: 24px;
  border-radius: 16px;
  box-shadow: var(--card-shadow-dark);
  text-align: center;
  max-width: 320px;
  z-index: 1000;
  animation: slideIn .3s ease-out;
  border: 1px solid rgba(171, 150, 255, .2)
}

@keyframes slideIn {
  0% {
    transform: translate(-50%, -40%);
    opacity: 0
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1
  }
}

.register-prompt h2 {
  color: var(--primary-color);
  margin-bottom: 16px;
  font-size: 20px
}

.register-prompt p {
  margin-bottom: 24px;
  line-height: 1.5;
  opacity: .8;
  color: var(--text-dark)
}

.register-button {
  background: var(--primary-color);
  color: var(--bg-dark);
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all .2s ease
}

.register-button:hover {
  background: var(--primary-hover);
  transform: translateY(-2px);
  box-shadow: var(--glow-effect)
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000080;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease
}

.overlay.active {
  opacity: 1;
  pointer-events: auto
}

.start-screen {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center
}

.welcome-text {
  color: #fff;
  font-size: 28px;
  margin-bottom: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  width: 100%;
  padding: 0 20px;
  text-shadow: 0 0 10px rgba(171, 150, 255, .5)
}

.description {
  color: #ffffffb3;
  margin-bottom: 24px;
  line-height: 1.6;
  text-align: left;
  width: 100%;
  padding: 0 20px
}

.start-button {
  background: #a699db;
  color: var(--bg-dark);
  border: none;
  padding: 12px 32px;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all .2s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  opacity: .5
}

.start-button.active {
  background-color: #a699db; /* Cor de destaque */
  cursor: pointer; /* Ativa o cursor */
  opacity: 1
}

.start-button:disabled {
  cursor: not-allowed;
  pointer-events: none
}

.start-button:not(:disabled):hover {
  background: var(--primary-hover);
  transform: translateY(-2px);
  box-shadow: var(--glow-effect)
}

.start-button svg {
  width: 20px;
  height: 20px
}

.call-interface {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px; /* Largura mínima */
}

dotlottie-player {
  margin: 0 auto;
  display: block
}

.access-code-section {
  padding: 20px;
  background: #ab96ff08;
  border-radius: 12px;
  margin: 0 20px 24px;
  width: calc(100% - 40px)
}

.access-code-input {
  background: var(--card-dark);
  border: 1px solid rgba(171, 150, 255, .2);
  color: var(--text-dark);
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  font-size: 16px;
  text-align: center;
  letter-spacing: 4px;
  transition: all .2s ease
}

.access-code-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: var(--glow-effect)
}

.access-code-input::placeholder {
  color: #ffffff4d;
  letter-spacing: 1px
}

.access-code-label {
  display: block;
  margin-bottom: 8px;
  color: #ffffffb3;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: left
}

.control-button:disabled {
  background-color: #322b47;
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none
}

.access-error {
  color: var(--error-color);
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
  animation: fadeIn .3s ease-out
}

.control-button.mute.active {
  background-color: red;
}

.help-dialog-loading {
  display: flex; /* Para ativar o Flexbox */
  flex-direction: column; /* Empilha os elementos verticalmente */
  justify-content: center; /* Alinha verticalmente no centro */
  align-items: center; /* Alinha horizontalmente no centro */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.9);
  background: var(--card-dark);
  padding: 32px;
  border-radius: 24px;
  box-shadow: var(--card-shadow-dark);
  max-width: 480px;
  width: 90%;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.help-dialog-loading.active {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.help-content-loading h2 {
  color: var(--primary-color);
  margin-bottom: 16px;
  font-size: 24px;
  text-align: center;
}

.help-content-loading p {
  color: var(--text-dark);
  line-height: 1.6;
  margin-bottom: 24px;
  text-align: center;
}

.help-dialog-resum {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.9);
  background: var(--card-dark);
  padding: 15px;
  border-radius: 24px;
  box-shadow: var(--card-shadow-dark);
  max-width: 70%;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
  overflow: hidden;
}

.help-dialog-resum.active {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.help-content-resum {
  max-height: 90vh; /* Ajuste a altura conforme necessário */
  overflow-y: auto; /* Adiciona rolagem quando necessário */
  padding-right: 10px; /* Para evitar que a rolagem cubra o conteúdo */
}

.help-content-resum h2 {
  color: var(--primary-color);
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.help-content-resum p2 {
  color: var(--primary-color);
  line-height: 1.6;
  margin-bottom: 24px;
}

.help-content-resum p {
  color: var(--text-dark);
  line-height: 1.6;
  margin-bottom: 4px;
}

.help-content-resum h4 {
  color: var(--text-dark);
  text-align: center;
  margin-bottom: 24px;
}

